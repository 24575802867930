import { useEffect, useRef, useState } from "react";
import { CandidateSubHeader } from "../../../Components/CandidateSubHeader/CandidateSubHeader";
import { getPassedData } from "../../../Common/flowAfterLogin";
import { CandidateHeader } from "../../../Components/CandidateHeader/CandidateHeader";
import "./AssessmentRound1.scss";
import {
  round1Instructions,
  round1InstructionsDataScience,
  betweenTestInstructions,
  fullScreenExit,
  // multipleTabSwitch,
  finalQuestion,
} from "../../../mockData/roundInstructions/round1Instructions";
import { RoundInstructions } from "../../../Components/RoundInstructions/RoundInstructions";
import Popup from "../../../Components/Common/popUp/popUp";
import crosssIcon from "../../../Assets/SVG/Close.svg";
import sucessGreenCheck from "../../../Assets/SVG/roundCompleteCheck.svg";
import { BackToHome } from "../../../Components/BackToHome/BackToHome";
import blueClock from "../../../Assets/SVG/blueClock.svg";
import axios from "axios";
import { SuccessRoundMessage } from "../../../Components/SuccessRoundMessage/SuccessRoundMessage";
import { FailureMessage } from "../../../Components/FailureMessage/FailureMessage";
// import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { SuccessAndFailureMessage } from "../../../mockData/successAndFailMessage";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
// import WarningIcon from "@mui/icons-material/Warning";
// import tabSwitchWarning from "../.././../Assets/SVG/tabSwitchWarning.svg";
// import multipleTabSwitchWarning from "../../../Assets/SVG/multipleTabSwitchWarning.svg";
import { GlobalLoader } from "../../../Components/Common/GlobalLoader/GlobalLoader";
import { InstructionsUi } from "../../../Components/InstructionsForEachRound/InstructionsForEachRound";
import { FullScreenExited } from "../../../Components/FullScreenModeExit/FullScreenModeExit";
import {
  MultipleTabSwitchError,
  TabSwitchUi,
} from "../../../Components/Common/TabSwitch/TabSwitch";
import ErrorIcon from "@mui/icons-material/Error";
import { clearAllOldIntervals } from "../../../Common/resolve";
import { PreProctoringScreenRound1 } from "../../../Components/Round1Proctoring/PreProctoringScreenRound1/PreProctoringScreenRound1";
import io from "socket.io-client";

// const socketUrl = process.env.REACT_APP_SOCKET_URL;
const socketUrl = "https://uat-proctor.wissda.cloud/";
const APTI_API_GET = "/candidate/round1/questions/aptitude/get";
const APTI_API_SAVE = "/candidate/round1/questions/aptitude/save";
const DSA_API_GET = "/candidate/round1/questions/dsa/get";
const DSA_API_SAVE = "/candidate/round1/questions/dsa/save";
const LANGUAGE_API_GET = "/candidate/round1/questions/language/get";
const LANGUAGE_API_SAVE = "/candidate/round1/questions/language/save";
const DATABASE_API_GET = "/candidate/round1/questions/database/get";
const DATABASE_API_SAVE = "/candidate/round1/questions/database/save";
const DATASCIENCE_API_GET = "/candidate/round1/questions/datascience/get";
const DATASCIENCE_API_SAVE = "/candidate/round1/questions/datascience/save";

const initialConfig = {
  role: null,
  aptiQuestions: null,
  dsaQuestions: null,
  languageQuestions: [],
  dbQuestions: null,
  totalQuestions: null,
  totalTimeInMins: null,
};

const initQuestionAndChoices = {
  id: null,
  question: null,
  code: null,
  questionUrl: null,
  choices: [],
  answer: null,
  time: null,
  questionId: null,
};

export const AssessmentRound1 = () => {
  const [passedData, setPassedData] = useState([]);
  const [isRound1Clear, setIsRoundClear] = useState(false);
  const [startTest, setStartTest] = useState(false);
  const [viewPretest, setViewPretest] = useState(false);
  const [isRound1Failed, setIsRoundFailed] = useState({
    status: false,
    reApply: "",
  });
  const [viewSuccess, setViewSuccess] = useState(false);
  const [viewFail, setViewFail] = useState(false);
  const [reapplydate, setreApplydate] = useState("");
  const [multipleSwitchError, setmultipleSwitchError] = useState(false);
  const [preAttemptedQuestions, setPreAttemptedQuestions] = useState(0);
  const [isTerminated, setTerminated] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);
  const [viewProctoringScreen, setViewProctoringScreen] = useState(false);

  const handleStartTest = () => {
    setViewPretest(false);
    setStartTest(true);
  };
  const [candidateRole, setCandidateRole] = useState("");
  const [viewStartInstruction, setViewStartInstruction] = useState(false);
  // proctoring related
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState(null);
  const screenRecorderRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const socketRef = useRef(null);
  // proctoring related

  const checkForPassedRound = async (loader = true) => {
    clearAllOldIntervals();
    if (loader) {
      console.log("calling loader==>");
      setViewLoader(true);
    }

    try {
      const res = await getPassedData();
      const passedArray = res?.passed;
      console.log("checkForPassedRound1==>", passedArray);
      if (passedArray) {
        setPassedData([...passedArray]);
        if (passedArray.includes("Round1")) {
          setIsRoundClear(true);
          setViewSuccess(true);
        }
      }
      if (res?.failed?.includes("Round1")) {
        setIsRoundFailed({
          status: true,
          reApply: res?.retestAcceptance,
        });
        setViewFail(true);
        setreApplydate(res?.retestAcceptance);
      }
      if (res?.terminated?.includes("Round1")) {
        setmultipleSwitchError(true);
        setTerminated(true);
      }
    } catch (err) {
      console.log("checkForPassedRound error==>", err);
    } finally {
      if (loader) {
        setViewLoader(false);
      }
    }
  };

  const getPreAttemptedQuestions = async () => {
    try {
      const endpoint = "/candidate/round1/getConfig/preAttempted";
      const data = {
        // name: sessionStorage.getItem("email"),
        name: localStorage.getItem("email"),
      };
      const res = await axios.post(endpoint, data);
      if (res?.data?.questionsAttempted > 0) {
        setPreAttemptedQuestions(res?.data?.questionsAttempted);
        // console.log("preAttempted==> ", res?.data?.questionsAttempted);
      }
    } catch (err) {
      console.log("preAttemptedQuestions error==>", err);
    }
  };

  const getProfessionalDetails = async () => {
    try {
      const endpoint = "/candidate/details/get/professional";
      const data = { id: localStorage.getItem("email") };
      const res = await axios.post(endpoint, data);
      if (res?.data && res?.data?.subfield === "Data Science Engineer") {
        // console.log("getProfessionalDetails===> data science");
        setCandidateRole("Data_Science");
      }
    } catch (err) {
      console.error("error in getProfessionalDetails==>", err);
    }
  };

  const getAllData = async () => {
    setViewLoader(true);
    await checkForPassedRound(false);
    // await checkForPassedRound();
    await getPreAttemptedQuestions();
    await getProfessionalDetails();
    setViewLoader(false);
  };

  const startRecordings = async () => {
    try {
      const userName = localStorage.getItem("email") || "defaultUser";
      const round = "round1";

      // Initialize the socket connection with the given URL and query parameters
      console.log("socket url is ==>", socketUrl);
      socketRef.current = io(socketUrl, {
        query: { userName, round },
      });

      // Handle connection errors
      socketRef.current.on("connect_error", (err) => {
        console.error("Connection error:", err);
        setError("Connection error. Please check your network and try again.");
      });
      setError(null);

      // Start screen recording
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: { width: 1280, height: 720, frameRate: 15 },
      });
      const screenRecorder = new MediaRecorder(screenStream, {
        mimeType: "video/mp4",
        videoBitsPerSecond: 600000,
      });
      screenRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          // Emit screen recording data to the socket
          socketRef?.current?.emit("screen_data", event.data);
        }
      };
      screenRecorder.start(1000);
      screenRecorderRef.current = screenRecorder;

      // Start video and audio recording from camera
      const avStream = await navigator.mediaDevices.getUserMedia({
        video: { width: 640, height: 480, frameRate: 15 },
        audio: true,
      });
      const avRecorder = new MediaRecorder(avStream, {
        mimeType: "video/mp4",
        videoBitsPerSecond: 600000,
      });
      avRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          // Emit camera video and audio data to the socket
          socketRef?.current?.emit("video_data", event.data);
        }
      };
      avRecorder.start(1000);
      mediaRecorderRef.current = avRecorder;

      setIsRecording(true);
      if (!startTest) {
        // setViewProctoringScreen(false);
        // debugger;
        setViewStartInstruction(true);
      }
    } catch (error) {
      console.error("Error starting recordings:", error);
      setError(
        "Error starting recordings. Please check your permissions and try again."
      );
    }
  };

  const stopRecordings = () => {
    if (screenRecorderRef.current) {
      screenRecorderRef.current.stop();
      screenRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      screenRecorderRef.current = null;
    }
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      mediaRecorderRef.current = null;
    }
    setIsRecording(false);
    socketRef?.current?.emit("stop_recording");
  };

  useEffect(() => {
    getAllData();
    return () => {
      stopRecordings();
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  return (
    <>
      {viewLoader && <GlobalLoader />}
      {viewSuccess && (
        <SuccessRoundMessage
          // message={"You have successfully completed Round 1."}
          message={SuccessAndFailureMessage?.round1?.success}
          gotoRound={"/gotoRound2"}
          onclose={setViewSuccess}
          next={"Round 2"}
        />
      )}
      {viewFail && (
        <FailureMessage
          reapply={reapplydate}
          onclose={setViewFail}
          message={SuccessAndFailureMessage?.round1?.failure}
        />
      )}
      {startTest ? (
        <StartTestRound1
          setStartTest={setStartTest}
          checkForPassedRound={checkForPassedRound}
          preAttemptedQuestions={preAttemptedQuestions}
          setmultipleSwitchError={setmultipleSwitchError}
          setViewLoader={setViewLoader}
          candidateRole={candidateRole}
          stopRecordings={stopRecordings}
        />
      ) : viewPretest ? (
        <PrestartPage handleStartTest={handleStartTest} />
      ) : (
        <>
          <CandidateHeader />
          {viewProctoringScreen ? (
            <PreProctoringScreenRound1
              setViewProctoringScreen={setViewProctoringScreen}
              startRecordings={startRecordings}
              setViewStartInstruction={setViewStartInstruction}
              setViewPretest={setViewPretest}
              viewStartInstruction={viewStartInstruction}
              setViewLoader={setViewLoader}
            />
          ) : (
            <>
              {" "}
              <CandidateSubHeader passedData={passedData} />
              <RoundStartOrComplete
                isRound1Clear={isRound1Clear}
                setViewPretest={setViewPretest}
                isRound1Failed={isRound1Failed}
                preAttemptedQuestions={preAttemptedQuestions}
                isTerminated={isTerminated}
                setViewProctoringScreen={setViewProctoringScreen}
                viewStartInstruction={viewStartInstruction}
                setViewStartInstruction={setViewStartInstruction}
                // candidateRole={candidateRole}
              />
              <RoundInstructions
                roundInstructions={
                  candidateRole === "Data_Science"
                    ? round1InstructionsDataScience
                    : round1Instructions
                }
              />
            </>
          )}
        </>
      )}
      {multipleSwitchError && (
        <MultipleTabSwitchError
          setmultipleSwitchError={setmultipleSwitchError}
        />
      )}
    </>
  );
};

const RoundStartOrComplete = ({
  isRound1Clear,
  isRound1Failed,
  preAttemptedQuestions,
  isTerminated,
  setViewProctoringScreen,
  setViewStartInstruction,
  // candidateRole,
}) => {
  const [viewOtpVerification, setViewOtpVerification] = useState(false);
  const [viewSuccessVerification, setViewSuccessVerification] = useState(false);
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");

  //   to check if phone is verified
  // const checkPhoneVerification = async () => {
  //   try {
  //     //  api for phone verification status
  //     const endpoint = "/candidate/phone/verification";
  //     const data = {
  //       // name: sessionStorage.getItem("email"),
  //       name: localStorage.getItem("email"),
  //     };
  //     const response = await axios.post(endpoint, data);
  //     return response?.data;
  //   } catch (err) {
  //     console.log("checkPhoneVerification error==>", err);
  //     return null;
  //   }
  // };

  // const getPhoneFromPersonalDetails = async () => {
  //   try {
  //     const endpoint = "/candidate/details/get/personal";
  //     const data = {
  //       // id: sessionStorage.getItem("email"),
  //       id: localStorage.getItem("email"),
  //     };
  //     const res = await axios.post(endpoint, data);
  //     if (res) {
  //       // preFillPersonalDetails(res?.data);
  //       const phoneNumber = res?.data?.mobile ? res.data.mobile : null;
  //       console.log("success in getCandidatePersonalDetails", phoneNumber);
  //       setPhone(phoneNumber);
  //       return phoneNumber;
  //     }
  //   } catch (err) {
  //     console.log("error in getCandidatePersonalDetails", err);
  //   }
  // };

  //   to send otp
  const handleSendOtp = async (phoneNumber = phone) => {
    try {
      // api for sending otp
      const endpoint = "/candidate/phone/sendOtp";
      const data = {
        // name: sessionStorage.getItem("email"),
        mobileNo: phoneNumber,
        message: "Hi, this message is from Wissda",
      };
      const response = await axios.post(endpoint, data);
      console.log("success in sendOtp", response?.data);
      const recievedOtp = response?.data?.otp ? response?.data?.otp : null;
      setOtp(recievedOtp);
      return recievedOtp;
    } catch (err) {
      console.log("handleSendOtp error==>", err);
    }
  };

  // check if mobile no. is verified
  // if yes view  the start test popup
  // if no ==>
  // view otp page option ,
  // if otp verified ==>
  //  view verified page ==> view start test popup
  // const handleClickStart = async () => {
  //   try {
  //     // const isPhoneVerified = await checkPhoneVerification();
  //     // if (isPhoneVerified) {
  //     //   setViewStartInstruction(true);
  //     // } else if (!isPhoneVerified) {
  //     //   const phone = await getPhoneFromPersonalDetails();
  //     //   if (phone) {
  //     //     const otp = await handleSendOtp(phone);
  //     //     setViewOtpVerification(true);
  //     //   }
  //     // }
  //     setViewStartInstruction(true);
  //   } catch (err) {
  //     console.log("handleClickStart error==>", err);
  //   }
  // };

  const handleClickStartToProctoring = () => {
    setViewProctoringScreen(true);
  };

  return (
    <>
      <div className="roundStartOrComplete">
        {/* <div>{candidateRole === "Data_Science" ? "30 Mins" : "45-60 Mins"}</div> */}
        <div>45-60 Mins</div>
        <div>Technical Fundamentals Test (TFT)</div>
        <div>Multiple Choice Questions & Coding Snippets</div>
        <div>
          We have developed a quiz based on your profile, please complete it to
          move forward.
        </div>

        {isTerminated ? (
          <div className="failed">
            Pause alert! Unethical antics crashed the assessment party. Contact
            support for a quick comeback plan.
          </div>
        ) : isRound1Failed.status ? null : isRound1Clear ? (
          <div className="completed">Completed</div>
        ) : (
          <div>
            {/* <button onClick={() => handleClickStart()}>*/}
            <button onClick={() => handleClickStartToProctoring()}>
              {preAttemptedQuestions > 0 ? "Resume Test" : " Start the Test"}
            </button>
          </div>
        )}

        {/* {isRound1Failed.status ? null : isRound1Clear ? (
          <div className="completed">Completed</div>
        ) : (
          <div>
            <button onClick={() => handleClickStart()}>
              {preAttemptedQuestions > 0 ? "Resume Test" : " Start the Test"}
            </button>
          </div>
        )} */}
      </div>
      {viewOtpVerification && (
        <PhoneVerification
          setViewOtpVerification={setViewOtpVerification}
          setViewSuccessVerification={setViewSuccessVerification}
          otp={otp}
          phone={phone}
          handleSendOtp={handleSendOtp}
        />
      )}
      {viewSuccessVerification && (
        <SuccessfullVerification
          setViewSuccessVerification={setViewSuccessVerification}
          setViewStartInstruction={setViewStartInstruction}
        />
      )}
    </>
  );
};

const PhoneVerification = ({
  setViewOtpVerification,
  setViewSuccessVerification,
  otp,
  phone,
  handleSendOtp,
}) => {
  const [timeLeft, setTimeLeft] = useState(30);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [otpEntered, setOtpEntered] = useState("");
  const [isWrongOtp, setIsWrongOtp] = useState(false);

  const handleResentOtp = async () => {
    try {
      // restart timmer
      // api/function for sending otp
      // Do something on click, e.g., resend the OTP
      // Reset timer
      const res = await handleSendOtp();
      setTimeLeft(30);
      setIsTimerActive(true);
    } catch (err) {
      console.log("handle resent", err);
    }
  };

  const setPhoneVerified = async () => {
    try {
      const endpoint = "/candidate/phone/verify";
      const data = {
        // name: sessionStorage.getItem("email"),
        name: localStorage.getItem("email"),
        mobile: phone,
        verified: true,
      };
      const res = await axios.post(endpoint, data);
      if (res?.data) return true;
      else {
        throw new Error("error in saving status");
      }
    } catch (err) {
      console.log("setPhoneVerified error==>", err);
    }
  };

  const handleSubmitOtp = async () => {
    if (otpEntered === "123456") {
      // if (otp === "123456") {
      const res = await setPhoneVerified();
      console.log("setPhoneVerified==>", res);
      setViewOtpVerification(false);
      setViewSuccessVerification(true);
    } else {
      setIsWrongOtp(true);
    }
  };

  useEffect(() => {
    // Exit early when we reach 0
    if (!timeLeft) {
      setIsTimerActive(false);
      return;
    }

    // Save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // Add timeLeft as a dependency to reset the interval
    // whenever the timeLeft changes
  }, [timeLeft, isTimerActive]);
  return (
    <Popup>
      <div className="otpVerificationContainer">
        <div className="close">
          <img
            src={crosssIcon}
            alt="close"
            onClick={() => setViewOtpVerification(false)}
          />
        </div>
        <div className="title">OTP Verification</div>
        <div className="message">
          An OTP has been sent to your mobile number{" "}
          <span>{`${phone[0]}${phone[1]}******${phone[8]}${phone[9]}`}</span>
        </div>
        <div className="enterOtp">Enter OTP</div>
        <div className="otpInput">
          <input
            className={`${isWrongOtp ? "wrong" : ""}`}
            type="number"
            name=""
            id=""
            value={otpEntered}
            onChange={(e) => {
              const value = e.target.value;
              if (!isNaN(value) && value.length <= 6) {
                setIsWrongOtp(false);
                setOtpEntered(e.target.value);
              }
            }}
            placeholder="Please enter the 6 digit OTP here to verify"
          />
        </div>
        {isWrongOtp && (
          <div className="wrong-error">
            {" "}
            <span>
              <ErrorIcon />
            </span>
            <span>Incorrect OTP entered</span>
          </div>
        )}
        <div className="notRecieved">
          <div>Still not received OTP?</div>
          <div>
            {timeLeft > 0 && <span>{timeLeft}</span>}
            <button
              disabled={timeLeft > 0}
              onClick={() => handleResentOtp()}
              style={{ cursor: timeLeft > 0 ? "not-allowed" : "" }}
            >
              {" "}
              Resend OTP
            </button>
          </div>
        </div>
        <div className="submit">
          <button onClick={() => handleSubmitOtp()}>Submit</button>
        </div>
      </div>
    </Popup>
  );
};

const SuccessfullVerification = ({
  setViewSuccessVerification,
  setViewStartInstruction,
}) => {
  return (
    <Popup>
      <div className="otpVerificationContainer">
        <div className="close">
          <img
            src={crosssIcon}
            alt="close"
            onClick={() => setViewSuccessVerification(false)}
          />
        </div>
        <div className="greenCheck">
          <img src={sucessGreenCheck} alt="" />
        </div>
        <div className="verificationMessage">
          Your mobile number has been successfully verified.
        </div>
        <div className="submit">
          <button
            onClick={() => {
              setViewSuccessVerification(false);
              setViewStartInstruction(true);
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </Popup>
  );
};

const PrestartPage = ({ handleStartTest }) => {
  const [timeLeft, setTimeLeft] = useState(3);
  const [isTimerActive, setIsTimerActive] = useState(true);
  // const [questions, setQuestions] = useState(45);

  useEffect(() => {
    // Exit early when we reach 0
    if (!timeLeft) {
      setIsTimerActive(false);
      handleStartTest();
      return;
    }

    // Save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // Add timeLeft as a dependency to reset the interval
    // whenever the timeLeft changes
  }, [timeLeft, isTimerActive]);

  return (
    <div className="prestartContainer">
      <BackToHome />
      <div className="pretestAcknowledge">
        <div className="detailsMain">
          <div className="round1">
            <div className="line"></div>
            <div>ROUND 1</div>
            <div className="line"></div>
          </div>
          {/* <div className="questions">{questions} Questions</div> */}
          <div className="starting">
            <div>Starting in</div>
            <div>{timeLeft}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StartTestRound1 = ({
  setStartTest,
  checkForPassedRound,
  preAttemptedQuestions,
  setmultipleSwitchError,
  setViewLoader,
  candidateRole,
  stopRecordings,
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(
    () => preAttemptedQuestions + 1
  );
  const [timer, setTimer] = useState(0);
  const [answer, setAnswer] = useState("");
  // Create a ref to keep track of the current answer
  const answerRef = useRef(answer);
  const [questionConfig, setQuestionConfig] = useState({ ...initialConfig });
  const [questionAndChoices, setQuestionAndChoices] = useState({
    ...initQuestionAndChoices,
  });
  const [languages, setLanguages] = useState({});
  const [switchCount, setSwitchCount] = useState(0);
  const [viewTabSwitch, setViewTabSwitch] = useState(false);
  // interval for status api
  const [statusInterval, setStatusInterval] = useState("");
  const [viewInstructions, setViewInstructions] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [viewFinalSubmit, setViewFinalSubmit] = useState(false);
  const [viewUi, setViewUi] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); //  flag to track submission status
  const isSubmittingRef = useRef(false); // Use ref to store the value of isSubmitting

  // function to tell backend that the ui is active and session is active
  // this is periodically called in some given time interval
  const handleApiCallForActiveStatus = async (tabSwitched = false) => {
    try {
      // const email = sessionStorage.getItem("email");
      const email = localStorage.getItem("email");

      const text = tabSwitched ? "tabSwitch" : "normal-ping";
      const body = {
        email,
        text,
      };
      console.log("before calling status api ==>");
      const endpoint = "/candidate/round1/proctoring/status/active";
      const res = await axios.post(endpoint, body);
      // const res = "";
      console.log("handleApiCallForActiveStatus success ==>", res);
    } catch (err) {
      console.log("handleApiCallForActiveStatus error ===>", err);
    }
  };

  const handleCloseScheduler = async () => {
    try {
      const data = {
        // name: sessionStorage.getItem("email"),
        name: localStorage.getItem("email"),
      };
      const endpoint = "/candidate/round1/proctoring/status/close";
      const res = await axios.post(endpoint, data);
      console.log("handleCloseScheduler success ==>", res);
    } catch (err) {
      console.log("handleCloseScheduler error ===>", err);
    }
  };

  // this will call handleApiCallForActiveStatus function every 50 sec to ping backend that the ui is active
  const handleStartIntervalForStatus = () => {
    // Retrieve and parse the intervals from sessionStorage
    let allIntervalsOld = JSON.parse(
      sessionStorage.getItem("allIntervals") || "[]"
    );

    // Clear all stored intervals
    allIntervalsOld.forEach((item) => {
      clearInterval(item);
    });

    const statusIntervalName = setInterval(() => {
      console.log("interval executed==>", statusIntervalName);
      handleApiCallForActiveStatus();
    }, 25000);

    // Store the new interval ID in sessionStorage
    allIntervalsOld = [statusIntervalName];
    sessionStorage.setItem("allIntervals", JSON.stringify(allIntervalsOld));

    setStatusInterval(statusIntervalName);
  };

  const saveR1Status = async () => {
    try {
      const endpoint = "/candidate/round1/status/save";
      const data = {
        // id: sessionStorage.getItem("email"),
        id: localStorage.getItem("email"),
        status: true,
      };
      const res = await axios.post(endpoint, data);
      if (res) {
        return;
      }
    } catch (err) {
      console.log("round1 saving status error ==>", err);
    }
  };

  const formatTime = (timer) => {
    const minutes = Math.floor(timer / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (timer % 60).toString().padStart(2, "0");
    if (minutes === NaN && seconds === NaN) return `00m:00s`;
    else return `${minutes}m:${seconds}s`;
  };

  const getQuestionConfig = async () => {
    try {
      const endpoint = "/candidate/round1/getConfig/questionConfig";
      const data = {
        // name: sessionStorage.getItem("email"),
        name: localStorage.getItem("email"),
      };
      const res = await axios.post(endpoint, data);
      // console.log("getQuestionConfig ==>", res);
      if (res?.data) {
        const langEndpoint = "/candidate/round1/getConfig/languages";
        const langres = await axios.post(langEndpoint, data);
        if (res?.data?.totalQuestions === preAttemptedQuestions) {
          setCurrentQuestion(res?.data?.totalQuestions);
          handleEndRound1Steps();
        }
        setQuestionConfig({ ...res?.data });
        setLanguages({ ...langres?.data });
      }
    } catch (err) {
      console.error("get Question config erro ==>", err);
    }
  };

  // Async function to fetch a question from a given API endpoint.
  const fetchQuestion = async (endpoint, data) => {
    try {
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.error("Error fetching question:", error);
      setStartTest(false);
      checkForPassedRound();
    }
  };

  const saveAnswer = async (endpoint, data) => {
    try {
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.error("Error saving answer:", error);
    }
  };

  const getNextQuestionNew = async (nextQuestionNo = currentQuestion) => {
    const data = {
      // name: sessionStorage.getItem("email"),
      name: localStorage.getItem("email"),
    };
    console.log("current question no is==>", nextQuestionNo, questionConfig);
    try {
      let nextQuestion;
      // fetching questions
      if (nextQuestionNo <= questionConfig?.aptiQuestions) {
        nextQuestion = await fetchQuestion(APTI_API_GET, data);
      } else if (
        nextQuestionNo <=
        questionConfig?.aptiQuestions + questionConfig?.dsaQuestions
      ) {
        nextQuestion = await fetchQuestion(DSA_API_GET, data);
      } else if (
        nextQuestionNo <=
        questionConfig?.aptiQuestions +
          questionConfig?.dsaQuestions +
          questionConfig?.languageQuestions[languages?.languages?.[0]]
      ) {
        data["language"] = languages?.languages?.[0]
          ? languages?.languages?.[0]
          : "";
        nextQuestion = await fetchQuestion(LANGUAGE_API_GET, data);
      } else if (
        languages?.languages?.[1] &&
        questionConfig?.languageQuestions[languages?.languages?.[1]] > 0 &&
        nextQuestionNo <=
          questionConfig?.aptiQuestions +
            questionConfig?.dsaQuestions +
            questionConfig?.languageQuestions[languages?.languages?.[0]] +
            questionConfig?.languageQuestions[languages?.languages?.[1]]
      ) {
        data["language"] = languages?.languages?.[1]
          ? languages?.languages?.[1]
          : "";
        nextQuestion = await fetchQuestion(LANGUAGE_API_GET, data);
      } else if (
        questionConfig?.dbQuestions &&
        questionConfig?.dbQuestions > 0 &&
        nextQuestionNo <=
          questionConfig?.aptiQuestions +
            questionConfig?.dsaQuestions +
            (questionConfig?.languageQuestions[languages?.languages?.[0]] ??
              0) +
            (questionConfig?.languageQuestions[languages?.languages?.[1]] ??
              0) +
            questionConfig?.dbQuestions
      ) {
        data["database"] = languages?.databases?.[0]
          ? languages?.databases?.[0]
          : "";
        nextQuestion = await fetchQuestion(DATABASE_API_GET, data);
      }
      // dataScienceQuestions
      else if (
        candidateRole === "Data_Science" &&
        nextQuestionNo <=
          questionConfig?.aptiQuestions +
            questionConfig?.dsaQuestions +
            (questionConfig?.languageQuestions[languages?.languages?.[0]] ??
              0) +
            (questionConfig?.languageQuestions[languages?.languages?.[1]] ??
              0) +
            questionConfig?.dbQuestions +
            questionConfig?.dataScienceQuestions
      ) {
        nextQuestion = await fetchQuestion(DATASCIENCE_API_GET, data);
      }

      // after fetching questions
      if (nextQuestion?.question === "" || nextQuestion?.question === null) {
        throw new Error(" question is null");
      }
      // console.log("nextQuestion answer=>", nextQuestion?.answer);
      setQuestionAndChoices({ ...initQuestionAndChoices, ...nextQuestion });
      const timerOfQuestion = nextQuestion?.time?.split(" ")[0];
      setTimer(Number(timerOfQuestion) * 60);
      // setTimer(20);

      setCurrentQuestion(nextQuestionNo);
    } catch (err) {
      console.log("error in getNextQuestionNew ", err);
      setCurrentQuestion(0);
      setTimer(0);
      setAnswer("");
      setStartTest(false);
      checkForPassedRound();
    } finally {
      setViewLoader(false);
      setDisableNextButton(false);
      if (!viewUi) {
        setViewUi(true);
      }
    }
  };

  const handleEndRound1Steps = async () => {
    setViewLoader(true);
    console.log("round is completed");
    setQuestionAndChoices({ ...initQuestionAndChoices });
    await handleCloseScheduler();
    await saveR1Status();
    stopRecordings();
    setStartTest(false);
    checkForPassedRound();
    setViewLoader(false);
  };

  const submitAnswer = async () => {
    if (isSubmittingRef.current) return;
    console.log("current question no is for saving==>", currentQuestion);
    setDisableNextButton(true);

    setIsSubmitting(true); // Set the flag to true when submission sta
    isSubmittingRef.current = true; // Set ref to true immediately

    if (currentQuestion - 1 === questionConfig?.totalQuestions) return;

    const data = {
      // name: sessionStorage.getItem("email"),
      name: localStorage.getItem("email"),
      answer: answerRef.current,
    };

    console.log("saving answer==>", data);
    // const currentQuestionForSaving = currentQuestion - 1;
    const currentQuestionForSaving = currentQuestion;
    console.log("saving current question no.==>", currentQuestionForSaving);
    try {
      if (
        questionAndChoices?.question === "" ||
        questionAndChoices?.question === null
      )
        throw new Error("no question present");
      // Submit answer to backend, handle currentQuestion incrementation and fetching of next question
      let submitRes;
      if (currentQuestionForSaving <= questionConfig?.aptiQuestions) {
        submitRes = await saveAnswer(APTI_API_SAVE, data);
      } else if (
        currentQuestionForSaving <=
        questionConfig?.aptiQuestions + questionConfig?.dsaQuestions
      ) {
        submitRes = await saveAnswer(DSA_API_SAVE, data);
      } else if (
        currentQuestionForSaving <=
        questionConfig?.aptiQuestions +
          questionConfig?.dsaQuestions +
          questionConfig?.languageQuestions[languages?.languages?.[0]]
      ) {
        // All questions have been answered, or logic for fetching additional question types
        submitRes = await saveAnswer(LANGUAGE_API_SAVE, data);
      } else if (
        languages?.languages?.[1] &&
        questionConfig?.languageQuestions[languages?.languages?.[1]] > 0 &&
        currentQuestionForSaving <=
          questionConfig?.aptiQuestions +
            questionConfig?.dsaQuestions +
            questionConfig?.languageQuestions[languages?.languages?.[0]] +
            questionConfig?.languageQuestions[languages?.languages?.[1]]
      ) {
        submitRes = await saveAnswer(LANGUAGE_API_SAVE, data);
      } else if (
        questionConfig?.dbQuestions &&
        questionConfig?.dbQuestions > 0 &&
        currentQuestionForSaving <=
          questionConfig?.aptiQuestions +
            questionConfig?.dsaQuestions +
            (questionConfig?.languageQuestions[languages?.languages?.[0]] ??
              0) +
            (questionConfig?.languageQuestions[languages?.languages?.[1]] ??
              0) +
            questionConfig?.dbQuestions
      ) {
        submitRes = await saveAnswer(DATABASE_API_SAVE, data);
      }

      // dataScienceQuestions
      else if (
        candidateRole === "Data_Science" &&
        currentQuestion <=
          questionConfig?.aptiQuestions +
            questionConfig?.dsaQuestions +
            (questionConfig?.languageQuestions[languages?.languages?.[0]] ??
              0) +
            (questionConfig?.languageQuestions[languages?.languages?.[1]] ??
              0) +
            questionConfig?.dbQuestions +
            questionConfig?.dataScienceQuestions
      ) {
        submitRes = await saveAnswer(DATASCIENCE_API_SAVE, data);
      }

      if (currentQuestion === questionConfig?.totalQuestions) {
        handleEndRound1Steps();
        return;
      }

      setAnswer("");
      await getNextQuestionNew(currentQuestion + 1);
    } catch (error) {
      console.error("Error submitting answer:", error);
      setAnswer("");
      setTimer(0);
      return;
    } finally {
      // setDisableNextButton(false);
      setIsSubmitting(false);
      isSubmittingRef.current = false;
    }
  };
  const enterFullScreen = () => {
    document.documentElement.requestFullscreen().catch((e) => {
      console.error("Error attempting to enable full-screen mode:", e);
    });
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen().catch((e) => {
        console.error("Error attempting to exit full-screen mode:", e);
      });
    }
  };

  const handleFullScreenChange = () => {
    if (!document.fullscreenElement) {
      setShowPopup(true);
      setIsFullscreen(false);
    } else {
      setIsFullscreen(true);
    }
  };

  // detecting tab switching
  const handleVisibilityChange = () => {
    if (document.hidden) {
      // Tab switched out or window minimized
      setSwitchCount((prevCount) => prevCount + 1);
    }
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((e) => {
        console.error("Error attempting to enable full-screen mode:", e);
      });
      setShowPopup(false);
    } else {
      exitFullScreen();
    }
  };

  useEffect(() => {
    setViewLoader(true);
    enterFullScreen();
    getQuestionConfig();

    // Add event listeners for fullscreen and visibility changes
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Start status interval if not already started
    !statusInterval && handleStartIntervalForStatus();
    handleApiCallForActiveStatus();

    // Cleanup event listeners and intervals when the component unmounts
    return () => {
      exitFullScreen();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(statusInterval);
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    // Start the test by getting the first question
    if (questionConfig.totalQuestions) {
      // getNextQuestion();
      getNextQuestionNew();
    }
  }, [questionConfig]);

  useEffect(() => {
    // Set up a timer for the current question
    const countdown = setInterval(() => {
      setTimer((prevTime) => {
        if (prevTime === 0) {
          if (!isSubmittingRef.current) {
            console.log("calling submitAnswer from timer ");
            submitAnswer().then(() => clearInterval(countdown)); // Call submitAnswer only if not submitting
          }
          return 0;
        } else return prevTime - 1;
      });
    }, 1000);

    // Clean up the timer
    return () => clearInterval(countdown);
  }, [questionAndChoices]);

  // Update the ref whenever the answer changes
  useEffect(() => {
    answerRef.current = answer;
  }, [answer]);

  useEffect(() => {
    if (switchCount > 0) {
      console.log("tab switched", switchCount);
      handleApiCallForActiveStatus(true);
      if (switchCount >= 3) {
        handleCloseScheduler();
        setStartTest(false);
        setmultipleSwitchError(true);
        checkForPassedRound();
      } else {
        setViewTabSwitch(true);
      }
    }
  }, [switchCount]);

  return (
    <>
      {viewFinalSubmit && (
        <FinalQuestionSubmit
          submit={submitAnswer}
          cancelSubmit={setViewFinalSubmit}
        />
      )}
      {viewInstructions && (
        <InstructionsUi
          setViewInstructions={setViewInstructions}
          betweenTestInstructions={betweenTestInstructions}
        />
      )}
      {viewUi && (
        <div className="startedRound1Container">
          <div className="generalDetails">
            <div
              className="instructions"
              onClick={() => setViewInstructions(true)}
            >
              {/* <BackToHome /> */}
              Instructions
              <DescriptionOutlinedIcon />
            </div>
            <div className="questionNumber">
              <span>
                {currentQuestion < 10 && currentQuestion !== 0
                  ? `0${currentQuestion}`
                  : currentQuestion}
              </span>
              <span>/</span>
              <span>{questionConfig?.totalQuestions}</span>
            </div>
            <div className="clock">
              <div>
                <img src={blueClock} alt="" />
              </div>
              <div className="time">
                {" "}
                <span>{formatTime(timer)}</span>
              </div>
            </div>
          </div>
          <div className="questionDetailsContainer">
            <QuestionAndAnswer
              question={questionAndChoices?.question}
              options={questionAndChoices?.choices}
              currentQuestion={currentQuestion}
              answer={answer}
              code={questionAndChoices?.code}
              setAnswer={setAnswer}
            />
            <div className="nextButton">
              <button
                disabled={disableNextButton}
                id="next-button"
                style={{ opacity: disableNextButton ? 0.7 : 1 }}
                onClick={() =>
                  currentQuestion === questionConfig?.totalQuestions
                    ? setViewFinalSubmit(true)
                    : submitAnswer()
                }
              >
                {currentQuestion === questionConfig?.totalQuestions
                  ? "Submit"
                  : "Next"}
              </button>
            </div>
          </div>
          {viewTabSwitch && <TabSwitchUi setViewTabSwitch={setViewTabSwitch} />}
          {showPopup && (
            <FullScreenExited
              toggleFullScreen={toggleFullScreen}
              fullScreenExit={fullScreenExit}
            />
          )}
        </div>
      )}
    </>
  );
};

const QuestionAndAnswer = ({
  question,
  options,
  currentQuestion,
  answer,
  setAnswer,
  code,
}) => {
  const handleRightClick = (event) => {
    event.preventDefault();
  };

  return (
    <div
      className="questionAndOption"
      onContextMenu={(e) => handleRightClick(e)}
    >
      <div className="questionNo">Question {currentQuestion}</div>
      <div className="question">{question}</div>
      {code && <div>{code}</div>}
      <div className="options">
        {options?.map((value, index) => (
          <div
            key={index}
            className={`eachOption ${value === answer ? "active" : ""}`}
            onClick={() => setAnswer(value)}
          >
            <input
              type="radio"
              value={value}
              // onChange={(e) => setAnswer(e.target.value)}
              checked={value === answer}
              onClick={() => setAnswer(value)}
            />
            <span> {value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const FinalQuestionSubmit = ({ submit, cancelSubmit }) => {
  return (
    <Popup>
      <div className="finalQuestionSubmit-wrapper">
        <div className="close" onClick={() => cancelSubmit(false)}>
          <img src={crosssIcon} alt="" />
        </div>
        <div className="title">{finalQuestion?.title}</div>
        <div className="buttons">
          <button className="button1" onClick={() => cancelSubmit(false)}>
            {finalQuestion?.button1}
          </button>
          <button className="button2" onClick={() => submit()}>
            {finalQuestion?.button2}
          </button>
        </div>
      </div>
    </Popup>
  );
};

// let nextInterval;
// function clickNext() {
//   let count = 0;
//   nextInterval = setInterval(() => {
//     if (count === 40) clearInterval(nextInterval);
//     else {
//       document.getElementById("next-button").click();
//       count++;
//     }
//   }, 3000);
// }
// clickNext();

// "role": "Data Science Engineer",
//     "aptiQuestions": 0,
//     "aptiEasyQuestionInPercent": 0,
//     "aptiMediumQuestionInPercent": 0,
//     "aptiDifficultQuestionInPercent": 0,
//     "dsaQuestions": 0,
//     "dsaEasyQuestionInPercent": 0,
//     "dsaMediumQuestionInPercent": 0,
//     "dsaDifficultQuestionInPercent": 0,
//     "languageQuestions": {
//         "Python": 15
//     },
//     "languageQuestionInPercent": {
//         "Easy": 33,
//         "Medium": 33,
//         "Difficult": 34
//     },
//     "dbQuestions": 15,
//     "dbEasyQuestionInPercent": 33,
//     "dbMediumQuestionInPercent": 33,
//     "dbDifficultQuestionInPercent": 34,
//     "dataScienceQuestions": 15,
//     "dataScienceEasyQuestionInPercent": 33,
//     "dataScienceMediumQuestionInPercent": 33,
//     "dataScienceDifficultQuestionInPercent": 34,
//     "totalQuestions": 45,
//     "totalTimeInMins": 45
